// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-index-index-tsx": () => import("./../src/templates/blogIndex/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-index-tsx" */),
  "component---src-templates-blog-template-index-tsx": () => import("./../src/templates/blogTemplate/index.tsx" /* webpackChunkName: "component---src-templates-blog-template-index-tsx" */),
  "component---src-templates-landing-page-index-tsx": () => import("./../src/templates/landingPage/index.tsx" /* webpackChunkName: "component---src-templates-landing-page-index-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-applinks-openbanking-consent-index-tsx": () => import("./../src/pages/applinks/openbanking/consent/index.tsx" /* webpackChunkName: "component---src-pages-applinks-openbanking-consent-index-tsx" */),
  "component---src-pages-applinks-recover-account-index-tsx": () => import("./../src/pages/applinks/recover-account/index.tsx" /* webpackChunkName: "component---src-pages-applinks-recover-account-index-tsx" */),
  "component---src-pages-applinks-recover-passcode-index-tsx": () => import("./../src/pages/applinks/recover-passcode/index.tsx" /* webpackChunkName: "component---src-pages-applinks-recover-passcode-index-tsx" */),
  "component---src-pages-applinks-verify-email-index-tsx": () => import("./../src/pages/applinks/verify-email/index.tsx" /* webpackChunkName: "component---src-pages-applinks-verify-email-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-emergency-access-card-index-tsx": () => import("./../src/pages/emergency-access/card/index.tsx" /* webpackChunkName: "component---src-pages-emergency-access-card-index-tsx" */),
  "component---src-pages-emergency-access-email-expired-index-tsx": () => import("./../src/pages/emergency-access/email-expired/index.tsx" /* webpackChunkName: "component---src-pages-emergency-access-email-expired-index-tsx" */),
  "component---src-pages-emergency-access-email-sent-index-tsx": () => import("./../src/pages/emergency-access/email-sent/index.tsx" /* webpackChunkName: "component---src-pages-emergency-access-email-sent-index-tsx" */),
  "component---src-pages-emergency-access-index-tsx": () => import("./../src/pages/emergency-access/index.tsx" /* webpackChunkName: "component---src-pages-emergency-access-index-tsx" */),
  "component---src-pages-facebook-index-tsx": () => import("./../src/pages/facebook/index.tsx" /* webpackChunkName: "component---src-pages-facebook-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-fscs-index-tsx": () => import("./../src/pages/fscs/index.tsx" /* webpackChunkName: "component---src-pages-fscs-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-privacy-policy-full-index-tsx": () => import("./../src/pages/privacy-policy/full/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-full-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-twitter-index-tsx": () => import("./../src/pages/twitter/index.tsx" /* webpackChunkName: "component---src-pages-twitter-index-tsx" */),
  "component---src-pages-unboxing-competition-terms-conditions-index-tsx": () => import("./../src/pages/unboxing-competition-terms-conditions/index.tsx" /* webpackChunkName: "component---src-pages-unboxing-competition-terms-conditions-index-tsx" */),
  "component---src-pages-web-authenticate-web-index-tsx": () => import("./../src/pages/web/authenticate-web/index.tsx" /* webpackChunkName: "component---src-pages-web-authenticate-web-index-tsx" */),
  "component---src-pages-website-terms-and-conditions-index-tsx": () => import("./../src/pages/website-terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-website-terms-and-conditions-index-tsx" */)
}

